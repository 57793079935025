export const LEAD_PROBABILITY = {

    LOW: {
        value: 15,
        code: 'LOW',
        color: "blue-grey lighten-4",
        name: "Low"
    },
    MEDIUM: {
        value: 30,
        code: 'MEDIUM',
        color: "teal lighten-2",
        name: "Medium"
    },
    MOST_LIKELY: {
        value: 60,
        code: 'MOST_LIKELY',
        color: "cyan lighten-1",
        name: "Most Likely"
    },
    HIGH: {
        value: 80,
        code: 'HIGH',
        color: "deep-orange lighten-2",
        name: "High"
    },
    CONFIDENT: {
        value: 100,
        code: 'CONFIDENT',
        color: "primary",
        name: "Confident"
    }
}