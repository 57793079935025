<template>
  <div class="lead-item-component">
    <v-card
      dark
      class="lead-item-card bg-grey-gradient"
      @mouseover="() => $emit('mouseover', value)"
      @mouseleave="() => $emit('mouseleave', value)"
    >
      <div :class="{ active }" class="item-active-indicator"></div>

      <v-card-title class="white--text pa-0">
        <v-row class="fill-height flex-column" justify="space-between">
          <v-list-item>
            <v-list-item-icon class="ma-0 mt-3 mr-2">
              <v-icon
                v-if="value.probability === 'HIGHEST'"
                class="mb-1"
                color="success"
                >mdi-chevron-double-up</v-icon
              >
              <v-icon
                v-else-if="value.probability === 'HIGH'"
                class="mb-1"
                color="lime lighten-1"
                >mdi-chevron-up</v-icon
              >
              <v-icon
                v-else-if="value.probability === 'MEDIUM'"
                class="mb-1"
                color="deep-orange darken-2"
                >mdi-chevron-down</v-icon
              >
              <v-icon v-else class="mb-1" color="error"
                >mdi-chevron-double-down</v-icon
              >
            </v-list-item-icon>
            <v-list-item-content class="">
              <v-tooltip
                :disabled="value.name && value.name.length < 30"
                bottom
                color="primary"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-list-item-title
                    class="mb-1 lead-title"
                    v-bind="attrs"
                    v-on="on"
                  >
                    {{ value.name }}
                  </v-list-item-title>
                </template>
                <span>{{ value.name }}</span>
              </v-tooltip>

              <v-list-item-subtitle>{{
                value.description
              }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-row>

        <v-tooltip bottom color="primary">
          <template v-slot:activator="{ on, attrs }">
            <div
              v-bind="attrs"
              v-on="on"
              class="probability-badge"
              :class="probabilityValueAndColor.color"
            ></div>
          </template>
          <span>Probability: {{ probabilityValueAndColor.name }}</span>
        </v-tooltip>
      </v-card-title>

      <v-card-text class="tags-list pa-2">
        <v-tooltip bottom v-if="value.analyzeCategory">
          <template v-slot:activator="{ on, attrs }">
            <v-chip
              label
              class="mb-1"
              color="primary"
              outlined
              v-bind="attrs"
              v-on="on"
            >
              <v-icon class="mr-2">mdi-table-cog</v-icon>
              {{ value.analyzeCategory.name }}
            </v-chip>
          </template>
          <span>Analyze Category</span>
        </v-tooltip>

        <v-divider class="my-2"></v-divider>

        <v-tooltip bottom v-if="value.upworkLead && value.upworkLead.estBudget">
          <template v-slot:activator="{ on, attrs }">
            <v-chip label class="mb-1" v-bind="attrs" v-on="on">
              <v-icon class="mr-2">mdi-currency-usd</v-icon>
              {{ formatPrice(value.upworkLead.estBudget * 100) }}
            </v-chip>
          </template>
          <span>Fixed Price Budget</span>
        </v-tooltip>
        <v-tooltip
          bottom
          v-if="
            value.upworkLead &&
            (value.upworkLead.minHourRate || value.upworkLead.maxHourRate)
          "
        >
          <template v-slot:activator="{ on, attrs }">
            <v-chip label class="mb-1" v-bind="attrs" v-on="on">
              <v-icon class="mr-2">mdi-cash-clock</v-icon>
              {{ formatPrice(value.upworkLead.minHourRate * 100) }}
              -
              {{ formatPrice(value.upworkLead.maxHourRate * 100) }}
            </v-chip>
          </template>
          <span>Hourly Rate</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-chip label class="mb-2" v-bind="attrs" v-on="on">
              <v-icon class="mr-2">mdi-thermometer</v-icon>

              {{
                (value.valid || value.valid === 0
                  ? value.valid
                  : value.upworkLead &&
                    (value.upworkLead.valid || value.upworkLead.valid === 0)
                  ? value.upworkLead.valid
                  : 0
                ).toFixed(2)
              }}/{{
                (value.invalid || value.invalid === 0
                  ? value.invalid
                  : value.upworkLead &&
                    (value.upworkLead.invalid || value.upworkLead.invalid === 0)
                  ? value.upworkLead.invalid
                  : 0
                ).toFixed(2)
              }}
            </v-chip>
          </template>
          <span>Lead Validity: (Valid/Invalid)</span>
        </v-tooltip>
      </v-card-text>
      <!-- <v-card-actions>

        <v-spacer></v-spacer>
        Est. Deal: ${{ value.cost }}
      </v-card-actions> -->
    </v-card>
  </div>
</template>



<script>
import { LEAD_PROBABILITY } from "../../../constants/defaults/Leads";
import { FormatterHelper } from "../../../helpers/formatter.helper";

export default {
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
    loading: {
      type: Boolean,
      default: false,
    },
    disappear: {
      type: Boolean,
      default: false,
    },
    loadingEnd: {
      type: Boolean,
      default: false,
    },
    active: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      pStar: this.star,
      transparent: "rgba(255, 255, 255, 0)",
      formatPrice: FormatterHelper.price,
    };
  },
  computed: {
    probabilityValueAndColor() {
      return (
        LEAD_PROBABILITY[this.value.probability] || {
          value: 0,
          color: "",
        }
      );
    },
  },
  methods: {
    onStar() {
      console.log("onStar");
      this.value.star = !this.value.star;
      console.log(this.value);
    },
    select() {
      this.$emit("onSelected", this.value);
    },
    changed() {
      this.$emit("onChange", this.value);
    },
  },
  // watch: {
  //   value(newVal) {
  //     console.log("VAL222: ", newVal);
  //   },
  // },
};
</script>


<style lang="scss" scoped>
.lead-item-component {
  cursor: pointer;

  .disappear {
    opacity: 0;
  }

  .add-btn-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .lead-item-card {
    transition: all 0.2s ease-in-out;
    overflow: hidden;

    .probability-badge {
      position: absolute;
      height: 40px;
      width: 40px;
      top: 0;
      right: 0;
      clip-path: polygon(50% 0%, 100% 26%, 100% 48%, 10% 0%);
    }

    .lead-title {
      overflow: hidden;
      text-overflow: ellipsis;
      width: 10px;
    }

    .tags-list {
      display: flex;
      flex-direction: column;
    }
  }

  .v-card {
    transition: opacity 0.4s ease-in-out;
  }
}
</style>