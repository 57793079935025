<template>
  <AView
    :value="value"
    :hasFilters="!!(filter.search || filter.type)"
    @onActivate="(e) => $emit('onActivate', e)"
    @onMaximize="(e) => $emit('onMaximize', e)"
    @onClose="(e) => $emit('onClose', e)"
    @onExpand="(e) => $emit('onExpand', e)"
    @onCollapse="(e) => $emit('onCollapse', e)"
  >
    <template v-slot:content="{}">
      <v-container class="sales-view main-list-right-panel">
        <!-- <FormSection
          left
          :label="'Leads List'"
          :icon="value.view.display.icon"
          :actions="actions"
          :editable="!!actions.length"
          :payload="{
            from: value,
          }"
        ></FormSection> -->
        <v-row class="ma-2">
          <v-col
            class="pa-0 pb-10 fooo"
            :style="{
              width: 100 / sections.length + '%!important',
            }"
            v-for="section in filterSections(sections)"
            :key="section.code"
          >
            <v-card
              class="pa-2 ma-2 bg-grey-gradient lead-status-header"
              :elevation="2"
            >
              <v-card-title class="pa-0">
                <span class="align-center">{{ section.name }} </span>
              </v-card-title>
            </v-card>

            <draggable
              v-model="section.leads"
              group="leads"
              :animation="1"
              :move="onMove"
              @start="isDragging = true"
              @end="isDragging = false"
              draggable="li"
              :id="section.code"
            >
              <transition-group
                class="lead-container"
                type="transition"
                name="y-list"
                tag="ul"
                :id="section.code"
              >
                <li
                  class="sales-item ma-2"
                  v-for="(lead, i) in section.leads"
                  :key="lead.id"
                  @click="() => onSelect(section.leads[i])"
                >
                  <LeadItemVue
                    class=".item"
                    :active="lead.id === activeLeadId"
                    @onSelected="() => onSelect(section.leads[i])"
                    @mouseover="onHover"
                    @mouseleave="() => {}"
                    v-model="section.leads[i]"
                  >
                  </LeadItemVue>
                </li>
              </transition-group>
            </draggable>
          </v-col>
        </v-row>
      </v-container>
    </template>
  </AView>
</template>
 

<script>
import draggable from "vuedraggable";
import { mapState } from "vuex";
import { PermissionsHelper } from "../../../../../components/helpers/permissions.helper";
// import FormSection from "../../../../../components/wad/atoms/common/FormSection.vue";
import LeadItemVue from "../../../../../components/wad/molecules/sales/LeadItem.vue";
import AView from "../../../../../components/wad/organisms/layout/A-View.vue";
import { LEAD_BUTTONS } from "../constants/actions.gh";

export default {
  props: {
    value: {
      default: () => ({}),
    },
  },
  data() {
    return {
      // api: LeadsApiInstance,
      filter: {},

      isDragging: false,
      delayedDragging: false,
      // statuses: [
      //   {
      //     code: "NEW",
      //     name: "New",
      //   },
      // ],

      tab: "table",
      afterMoveUpdateTimeout: undefined,
      sections: [],
      allowedStatuses: [
        "TAKEN_TO_WORK",
        "NO_REPLY",
        "ACTIVE",
        "VERBAL_OK",
        "DOCUMENTS_PREPARATION",
        "COMPLETED",
      ],
    };
  },
  components: {
    AView,
    draggable,
    LeadItemVue,
    // FormSection,
  },
  computed: {
    ...mapState("LeadStore", [
      "activeLeadId",
      "leads",
      "pagination",
      "board",
      "statuses",
    ]),
    actions: function () {
      return PermissionsHelper.getActions(
        LEAD_BUTTONS.CREATE_NEW,
        this.value.permissions
      );
    },
    boardItems: function () {
      return this.statuses.map((status) => ({
        name: status.name,
        code: status.code,
        leads: this.board[status.code],
      }));
    },
  },
  async created() {
    await this.$store.dispatch("LeadStore/GetLeadStatuses");

    this.$store.dispatch("LeadStore/GetAllMyLeads");
  },
  methods: {
    onHover(value) {
      this.$store.commit("LeadStore/setActiveId", value.id);
    },
    onSelect(lead) {
      this.$store.dispatch("LeadStore/SetActiveLead", {
        from: this.value,
        lead,
      });
    },
    filterSections(sections) {
      return sections
        .filter((sect) => this.allowedStatuses.find((el) => el === sect.code))
        .sort(
          (a, b) =>
            this.allowedStatuses.indexOf(a.code) -
            this.allowedStatuses.indexOf(b.code)
        );
    },

    checkMove: function (e) {
      console.log("Future index: " + e.draggedContext.futureIndex);
    },
    // onMove({ relatedContext, draggedContext }) {
    onMove(props) {
      const destinationStatus = this.statuses.find(
        (status) => status.code === props.to.id
      );
      const draggedElement = props.draggedContext.element;

      console.log("STATUS: ", destinationStatus);
      console.log("draggedElement: ", draggedElement);

      this.$store.dispatch("LeadStore/UpdateLead", {
        lead: {
          ...props.draggedContext.element,
          status: destinationStatus,
        },
      });

      if (this.afterMoveUpdateTimeout)
        clearTimeout(this.afterMoveUpdateTimeout);

      // this.afterMoveUpdateTimeout = setTimeout(() => {
      //   this.$store.dispatch("LeadStore/GetMyLeads", {
      //     status: destinationStatus.code,
      //   });
      // }, 200);

      return true;

      // return (
      //   (!relatedElement || !relatedElement.fixed) && !draggedElement.fixed
      // );
    },
  },
  watch: {
    filter: {
      handler(newVal) {
        this.$store.dispatch("LeadStore/GetMyLeads", {
          filter: newVal,
          reset: true,
        });
      },
      deep: true,
    },
    board: {
      handler(newVal) {
        // this.$store.dispatch("LeadStore/GetMyLeads", {
        //   filter: newVal,
        //   reset: true,
        // });

        this.sections = this.statuses.map((status) => ({
          name: status.name,
          code: status.code,
          leads: newVal[status.code],
        }));
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.sales-view {
  min-width: 1800px;

  .sales-view-container {
    padding-right: 50px;
  }

  .lead-status-header {
    border-bottom: 1px solid black;
    background: transparent;
    border-radius: 4px;
  }
  .lead-status-header::before {
    content: "";
    background: black;
    clip-path: polygon(85% 0%, 100% 50%, 85% 100%, 0% 100%, 15% 50%, 0% 0%);
    width: 100%;
    height: 100%;
  }

  .ghost {
    opacity: 0.5;
    background: transparent;
  }

  .sales-item {
    transition: all 0.5s;
    background: transparent;
  }
  .sales-item-enter, .sales-item-leave-to
/* .card-leave-active for <2.1.8 */ {
    opacity: 0;
    // transform: scale(0);
    opacity: 0;
  }
  .sales-item-enter-to {
    opacity: 1;
    transform: scale(1.1);
  }

  .sales-item-leave-active {
    /*position: absolute;*/
  }

  .sales-item-move {
    opacity: 1;
    transition: all 0.5s;
  }

  .sales-item {
    margin-top: 10px;
  }

  .lead-container {
    min-height: 300px;
    list-style: none;
    padding: 0;
  }

  //   padding: 0;
  //   @media (min-width: 1264px) {
  //     max-width: 100%;
  //   }

  //   @media (max-width: 1264px) {
  //     padding-left: 0px;
  //     padding-right: 0px;
  //     max-width: 100%;
  //   }

  //   margin-bottom: 30px;
}
/* .main-content {
  overflow-x: scroll !important;
} */
</style>



